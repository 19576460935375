 <template>
  <div>
    <HospitalizationRequestGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :invoicing_tiss_setting="invoicingTissSetting"
      :required_fields="required_fields"
      :tissGuide="tissGuide"
      :guideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      @print="print"
      :getHospitalizationGuidesNumbers="getHospitalizationGuidesNumbers"
      :providerGuideReadonly="providerGuideReadonly"
      :validateProviderGuide="validateProviderGuide"
      :guideNumberExistsInBatch="guideNumberExistsInBatch"
      :guideNumberExists="guideNumberExists"
      :providerGuideUpdated="providerGuideUpdated"
      :contracteds="contracteds"
      @update-form="updateForm"
      v-if="!isSus"
    />

    <ExpenseGuideModal
      :patient="patient"
      :healthPlan="clinicHealthPlan?.health_plan"
      :clinicHealthPlan="clinicHealthPlan"
      :invoicing_tiss_setting="invoicingTissSetting"
      :tissGuide="tissGuideOtherExpense"
      :providerGuideUpdated="providerGuideUpdated"
      :appointmentId="appointmentId"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      :contracteds="contracteds"
      @openSpsadtGuide="openSpsadtGuide"
      @print="print"
      v-if="!isSus"
    />

    <FeeGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :invoicing_tiss_setting="invoicingTissSetting"
      :tissGuide="tissGuide"
      :guideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      :hospitalizationGuidesNumbers="hospitalizationGuidesNumbers"
      @open-hospitalization-request-modal="openHospitalizationRequestModal"
      @print="print"
      :providerGuideReadonly="providerGuideReadonly"
      :validateProviderGuide="validateProviderGuide"
      :guideNumberExistsInBatch="guideNumberExistsInBatch"
      :guideNumberExists="guideNumberExists"
      :providerGuideUpdated="providerGuideUpdated"
      :contracteds="contracteds"
      @update-form="updateForm"
      v-if="!isSus"
    />

    <HospitalizationSummaryGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :invoicing_tiss_setting="invoicingTissSetting"
      :required_fields="required_fields"
      :tissGuide="tissGuide"
      :guideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      :hospitalizationGuidesNumbers="hospitalizationGuidesNumbers"
      @open-hospitalization-request-modal="openHospitalizationRequestModal"
      @print="print"
      :providerGuideReadonly="providerGuideReadonly"
      :validateProviderGuide="validateProviderGuide"
      :guideNumberExistsInBatch="guideNumberExistsInBatch"
      :guideNumberExists="guideNumberExists"
      :providerGuideUpdated="providerGuideUpdated"
      :contracteds="contracteds"
      @update-form="updateForm"
      v-if="!isSus"
    />

    <ConsultationGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :invoicing_tiss_setting="invoicingTissSetting"
      :required_fields="required_fields"
      :tissGuide="tissGuide"
      :guideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      @print="print"
      :providerGuideReadonly="providerGuideReadonly"
      :validateProviderGuide="validateProviderGuide"
      :guideNumberExistsInBatch="guideNumberExistsInBatch"
      :guideNumberExists="guideNumberExists"
      :providerGuideUpdated="providerGuideUpdated"
      :contracteds="contracteds"
      @update-form="updateForm"
      v-if="!isSus"
    />

    <SPSADTGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :invoicing_tiss_setting="invoicingTissSetting"
      :required_fields="required_fields"
      :tissGuide="tissGuide"
      :guideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @onHide="clearTissGuideMethod"
      @refresh-tiss-guides="getLastTissGuide"
      @print="print"
      :providerGuideReadonly="providerGuideReadonly"
      :validateProviderGuide="validateProviderGuide"
      :guideNumberExistsInBatch="guideNumberExistsInBatch"
      :guideNumberExists="guideNumberExists"
      :providerGuideUpdated="providerGuideUpdated"
      :contracteds="contracteds"
      @update-form="updateForm"
      @createOtherExpensesGuide="createOtherExpensesGuide"
      @openOtherExpensesGuide="openOtherExpensesGuide"
      v-if="!isSus"
    />

    <ConflictedGuidesModal
      :conflictedGuides="conflictedGuides"
      :currentGuideNumber="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number"
      @update-conflicted-guides="$event => conflictedGuides = $event"
      @update-invalid-guides="updateInvalidGuides"
      @update-provider-guide="updateProviderGuide"
      :clearProviderGuideUpdated="clearProviderGuideUpdated"
      v-if="!isSus"
    />

    <ApacGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :sus_information_setting="clinicHealthPlan?.sus_information_setting"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :susGuide=susGuide
      :checkedItems="checkedItems"
      @update-form="updateForm"
      @refresh-tiss-guides="getLastTissGuide"
    />

    <BpaGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :sus_information_setting="clinicHealthPlan?.sus_information_setting"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :susGuide=susGuide
      :checkedItems="checkedItems"
      @update-form="updateForm"
      @refresh-tiss-guides="getLastTissGuide"
    />
  </div>
</template>

<script>
import api from '@/modules/tiss/manageGuides/api'
import { saveAs } from 'file-saver'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { showOnlending } from '@/utils/permissionsHelp';
import { handleGuideSelected } from '@/utils/invoicingTissHelper'

export default {
  name: 'TissGuidesModalsContainer',
  components: {
    ConflictedGuidesModal: () => import('@/components/Tiss/Modal/ConflictedGuidesModal'),
    ConsultationGuideModal: () => import('@/components/Tiss/Guides/ConsultationGuideModal'),
    ExpenseGuideModal: () => import('@/components/Tiss/Guides/ExpenseGuideModal'),
    FeeGuideModal: () => import('@/components/Tiss/Guides/FeeGuideModal'),
    HospitalizationRequestGuideModal: () => import('@/components/Tiss/Guides/HospitalizationRequestGuideModal'),
    HospitalizationSummaryGuideModal: () => import('@/components/Tiss/Guides/HospitalizationSummaryGuideModal'),
    SPSADTGuideModal: () => import('@/components/Tiss/Guides/SPSADTGuideModal'),
    ApacGuideModal: () => import('@/components/Sus/Guides/ApacGuideModal'),
    BpaGuideModal: () => import('@/components/Sus/Guides/BpaGuideModal'),
  },
  props: {
    tissGuide: Object,
    clinicHealthPlan: Object,
    required_fields: Object,
    patient: Object,
    appointmentId: String,
    getLastTissGuide: Function,
    clearTissGuide: Function,
    getClinicHealthPlan: Function,
    setTissGuide: Function,
    getGuide: Function,
    checkedItems: Array,
    isSus: {
      type: Boolean,
      default: false
    },
    susGuide: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    clinic: getCurrentClinic(),
    hospitalizationGuidesNumbers: [],
    guideNumberExistsInBatch: false,
    guideNumberExists: false,
    guideType: null,
    providerGuide: null,
    providerGuideUpdated: null,
    conflictedGuides: [],
    form: {},
    contracteds: [],
    settingProfessional: null,
    tissGuideOtherExpense: {}
  }),
  methods: {
    showOnlending,
    async print(tissGuide) {
      const isLoading = this.$loading.show()
      try {
        const tissGuideAudit = await this.api.getTissGuideAudits(tissGuide.id).then(({data}) => {
          if(data?.length){
            const sortedArray = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            return sortedArray[0];
          } else {
            return null;
          }
        })
        
        // Puxa o nome de quem criou a guia da TissGuideAudit, caso não encontre, usa o nome de quem está imprimindo a guia
        const userName = tissGuideAudit?.person?.name || getCurrentUser().name;
        const isExpense = Boolean(tissGuide.expensable_id);

        const { data } = await this.api.printTissGuide(tissGuide.id, userName, isExpense) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, this.getFileName(tissGuide))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getFileName(tissGuide) {
      if(tissGuide.expensable_id) return `Guia outras despesas`

      const type = tissGuide.guide_type.split('\\').at(-1)
      const types = {
        TissGuideConsultation: `Guide de consulta - ${tissGuide.provider_guide}`,
        TissGuideSpSadt: `Guia SP/SADT - ${tissGuide.provider_guide}`,
        TissGuideHospitalizationRequest: `Guia solicitação de internação - ${tissGuide.provider_guide}`,
        TissGuideFee: `Guia de honorário - ${tissGuide.provider_guide}`,
        TissGuideHospitalizationSummary: `Guia resumo de internação - ${tissGuide.provider_guide}`,
        TissGuideOtherExpense: `Guia outras despesas`
      }
      return types[type]
    },
    openHospitalizationRequestModal() {
      this.$bvModal.show('hospitalization_request_guide_modal')
    },
    async getHospitalizationGuidesNumbers() {
      try {
        this.hospitalizationGuidesNumbers = []
        const { data } = await this.api.getHospitalizationRequestGuides(this.patient?.id, this.invoicingTissSetting?.id)
        data.map(guide => {
          this.hospitalizationGuidesNumbers.push({
            label: guide.provider_guide,
            value: guide.guide.id
          })
        }).sort((a, b) => b - a)
        this.hospitalizationGuidesNumbers = this.hospitalizationGuidesNumbers.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async validateProviderGuide(providerGuide) {
      this.guideNumberExistsInBatch = false
      this.guideNumberExists = false
      try {
        this.providerGuide = parseInt(providerGuide)
        const { data } = await this.api.guideNumberExist(providerGuide, this.clinicHealthPlan?.id)
        this.guideNumberExistsInBatch = data.in_batch
        this.guideNumberExists = data.exists
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getConflictedGuideNumbers(originalProviderGuide, providerGuideEdit, guideType) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getConflictedGuideNumbers(originalProviderGuide, providerGuideEdit, this.clinicHealthPlan?.id , guideType)
        this.conflictedGuides = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateForm({ form, providerGuide}) {
      if (form) {
        this.form = form
        this.$set(this.form, 'provider_guide_prop', providerGuide)
      }
    },
    async clearTissGuideMethod() {
      this.hospitalizationGuidesNumbers = []
      this.guideNumberExistsInBatch = false
      this.guideNumberExists = false
      this.guideType = null
      this.providerGuide = null
      this.providerGuideUpdated = null
      this.conflictedGuides = []
      this.form = {}
      this.clearTissGuide()

      if(this.getClinicHealthPlan){
        await this.getClinicHealthPlan(this.clinicHealthPlan?.invoicing_tiss_setting?.id)
      }
    },
    updateInvalidGuides(value) {
      this.guideNumberExistsInBatch = value
      this.guideNumberExists = value
    },
    clearProviderGuideUpdated() {
      this.providerGuideUpdated = null
    },
    updateProviderGuide(value) {
      this.providerGuideUpdated = parseInt(value)
    },
    async getProfessionals() {
      try {
        const { data } = await this.api.getInvoicingTissSettingProfessionals(
          this.invoicingTissSetting?.id
        )
        const contracteds = data.map(item => {
          return {
            label: item.professional.name,
            value: item.professional.name,
            operator_code: item.operator_code,
            cnes: this.invoicingTissSetting?.CNES,
            code_type: item.code_type
          }
        })

        this.contracteds = contracteds;
        if(!this.contracteds.find(el => el === this.settingProfessional)){
          this.contracteds = this.contracteds.concat(this.settingProfessional)
          this.orderContracteds()
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    orderContracteds(){
      const contractedsValue = [...this.contracteds]
      contractedsValue.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      })
      this.contracteds = contractedsValue
    },
    createOtherExpensesGuide(providerGuide) {
      this.tissGuideOtherExpense = {}
      const modal = handleGuideSelected('other_expenses_guide')
      this.$bvModal.show(modal)
      setTimeout(() => {
        this.providerGuideUpdated = providerGuide
      }, 800)
    },
    async openOtherExpensesGuide(otherExpenseId) {
      try {
        const { data } = await api.getOtherExpenseGuide(otherExpenseId)
        this.tissGuideOtherExpense = data
        this.$bvModal.show('other_expenses_guide_modal')
      } catch (error) {
        this.$toast.error(error.message)
      } 
    },
    async openSpsadtGuide(spsadtId) {
      const data = {
        id: spsadtId
      }
      await this.getGuide(data)
      this.$bvModal.show('spsadt-guide-modal')
    }
  },
  computed: {
    providerGuideReadonly() {
      return Boolean(this.tissGuide?.tiss_batch_id)
    },
    invoicingTissSetting() {
      return this.clinicHealthPlan?.invoicing_tiss_setting
    }
  },
  watch: {
    async clinicHealthPlan(value) {
      if (value && this.patient?.id) {
        await this.getHospitalizationGuidesNumbers()
      }
    },
    guideNumberExistsInBatch: {
      handler(newValue) {
        if (newValue) {
          this.$toast.error('Este Nº de guia já está em um lote, tente novamente')
        }
      },
      immediate: true
    },
    guideNumberExists: {
      async handler(newValue) {
        const originalProviderGuide = this.tissGuide?.id ? this.tissGuide.provider_guide : this.clinicHealthPlan?.invoicing_tiss_setting?.tiss_information?.current_guide_number
        if (parseInt(this.providerGuide) === this.providerGuideUpdated) return

        if (newValue && !this.guideNumberExistsInBatch) {
          await this.getConflictedGuideNumbers(originalProviderGuide, this.providerGuide, this.guideType)
          if (!this.form?.id) {
            this.conflictedGuides.unshift(this.form)
          }
          this.$bvModal.show('conflicted-guides-modal')
        }
      },
      immediate: true
    },
    invoicingTissSetting: {
      async handler(value) {
        if (!value?.id) return
        this.contracteds = []
        this.settingProfessional = {
          label: value?.hired_executant_name,
          value: value?.hired_executant_name,
          operator_code: value?.hired_executant_code,
          cnes: value?.CNES,
          code_type: value?.provider_code // 'Código do Contratado na Operadora'
        }
        await this.getProfessionals()
      },
      deep: true,
    }
  }
}
</script>
